/// <reference types="@types/googlemaps" />
import {
  Appearance,
  GermanAddress,
  Location,
} from '@angular-material-extensions/google-maps-autocomplete';
import { CommonModule } from '@angular/common';
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LeadsService } from '../services/leads.service';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
})
export class LeadComponent {
  form: FormGroup | any;
  files: any[] = [];
  fromCompanies: any = [];
  toCompanies: any = [];
  users: any = [];
  states: Array<string> = ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  success: boolean = false;
  currentCompanyId: any;
  currentUserId: any;
  isProcessing: boolean = false;
  isPageLoading: boolean = false;
  userCanChange: boolean = false;
  public zoom: number = 0;
  public latitude: number = 0;
  public longitude: number = 0;
  public appearance = Appearance;

  constructor(
    private fb: FormBuilder,
    private leadsService: LeadsService,
    public zone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.clearForm();

    this.isPageLoading = true;

    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;
    this.form['address'] = ' ';

    this.setCurrentPosition();
    this.leadsService.getCompanies().subscribe(
      (res) => {
        this.fromCompanies = res;

        this.leadsService.getCurrentUser().subscribe((userResult: any) => {
          this.isPageLoading = false;
          if (userResult != null) {
            this.currentUserId = userResult.id;
            this.userCanChange = userResult.canChange;
            this.currentCompanyId = userResult.companyId;
            if (this.currentCompanyId && this.currentCompanyId > 0)
              this.loadCompanyUsers(this.currentCompanyId);
          }

          this.prefillForm();
        });
      },
      (err) => {
        if (err.status == 401) this.router.navigateByUrl('unauthorized');
      }
    );
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  clearForm() {
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      fromUserId: [null, [Validators.required]],
      emailAddress: [
        null,
        [
          Validators.pattern(
            '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
          ),
        ],
      ],
      fromCompanyId: [null, [Validators.required]],
      toCompanyId: [null, [Validators.required]],
      address: [null],
      street_number: [null, [Validators.required]],
      route: [null, [Validators.required]],
      locality: [null, [Validators.required]],
      postal_code: [null, [Validators.required]],
      administrative_area_level_1: [null, [Validators.required]],
      phoneNumber: [null],
      note: [null],
    });
  }

  loadCompanyUsers(companyId: any) {
    this.toCompanies = this.fromCompanies.filter((x: any) => x.id != companyId);

    this.form.controls['fromUserId'].setValue(null, { onlySelf: true });
    this.form.value['fromUserId'] = null;
    this.form.controls['toCompanyId'].setValue(null, { onlySelf: true });
    this.form.value['toCompanyId'] = null;

    this.leadsService.getActiveCompanyUsers(companyId).subscribe((res: any) => {
      res.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName));
      this.users = res;
    });
  }

  prefillForm() {
    if (this.currentCompanyId != null) {
      this.form.controls['fromCompanyId'].setValue(this.currentCompanyId, {
        onlySelf: true,
      });
      this.form.value['fromCompanyId'] = this.currentCompanyId;

      this.form.controls['fromUserId'].setValue(this.currentUserId, {
        onlySelf: true,
      });
      this.form.value['fromUserId'] = this.currentUserId;
    }
    this.form.controls['address'].setValue('', {
      onlySelf: true,
    });
    this.form.value['address'] = '';
  }

  goBack() {
    this.router.navigateByUrl('/');
  }

  saveDetails(form: any) {
    this.isProcessing = true;
    console.log(form);
    this.leadsService.createLead(form.value, this.files).then((res) => {
      this.clearForm();
      this.files = [];
      this.success = true;
      this.isProcessing = false;
      this.prefillForm();
      // this.router.navigate(['/leads'], { queryParams: { success: true } });
    });
  }

  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(target: any) {
    this.prepareFilesList(target.files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    // this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: any, decimals: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onAutocompleteSelected(result: PlaceResult) {
    result.address_components?.forEach((line) => {
      if (this.form.contains(line.types[0])) {
        this.form.controls[line.types[0]].setValue(line.long_name);
      }
    });
  }

  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }
}
