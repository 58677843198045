import { Component, Inject, isDevMode, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LeadsService } from '../services/leads.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent implements OnInit {
  title = 'Group Leads';
  isExpanded = false;
  devMode = isDevMode();
  modeColor = 'primary';
  userEmail: string = '';
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private leadsService: LeadsService
  ) {}

  ngOnInit(): void {
    if (isDevMode()) {
      this.modeColor = 'warn';
    }
    this.leadsService.getCurrentUser().subscribe((userResult: any) => {
      if (userResult != null) {
        this.userEmail = userResult.emailAddress;
      }
    });
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  // unsubscribe to events when component is destroyed

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  login() {
    // this.router.navigateByUrl('/leads');
    // return;
    try {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authService
            .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        } else {
          this.authService
            .loginPopup()
            .subscribe((response: AuthenticationResult) => {
              this.authService.instance.setActiveAccount(response.account);
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authService
            .loginRedirect({
              ...this.msalGuardConfig.authRequest,
            } as RedirectRequest)
            .subscribe(
              (_) => _,
              (err) => this.router.navigateByUrl('')
            );
        } else {
          this.authService.loginRedirect();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  logout() {
    this.authService.logout();
  }
}
