import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: './star-rating.component.html',
})
export class StarRatingComponent implements OnInit {
  @Input()
  get rating(): number {
    return this._rating;
  }
  set rating(rating: number) {
    this._rating = rating;
  }
  private _rating = 0;

  @Input() private starCount: number = 5;
  @Input() locked: boolean = true;

  ratingArr: number[] = [];

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) return 'star';
    else return 'star_border';
  }
}
