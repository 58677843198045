<div class="container" style="zoom: 100%">
  <div
    *ngIf="success"
    class="alert alert-success text-center mt-3"
    role="alert"
  >
    Lead created successfully!
  </div>
  <hr />
  <mat-spinner style="margin: 0 auto" *ngIf="isPageLoading"></mat-spinner>
  <form
    [formGroup]="form"
    (ngSubmit)="saveDetails(form)"
    enctype="multipart/form-data"
    *ngIf="!isPageLoading"
  >
    <div class="center-form">
      <div class="row" *ngIf="form.value.fromCompanyId != null">
        <mat-form-field
          appearance="fill"
          hintLabel="From User required"
          class="col-md-6"
        >
          <mat-label>From User</mat-label>
          <mat-select
            matNativeControl
            name="fromUserId"
            formControlName="fromUserId"
            [disabled]="!userCanChange"
          >
            <mat-option *ngFor="let item of users" [value]="item.id"
              >{{ item.firstName }} {{ item.lastName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field
          appearance="fill"
          hintLabel="From Company required"
          class="col-md-3"
        >
          <mat-label>From Company</mat-label>
          <mat-select
            matNativeControl
            name="fromCompanyId"
            formControlName="fromCompanyId"
            [disabled]="!userCanChange"
            (selectionChange)="loadCompanyUsers($event.value)"
          >
            <mat-option *ngFor="let item of fromCompanies" [value]="item.id">{{
              item.shortName
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="fill"
          hintLabel="To Company required"
          class="col-md-3"
          *ngIf="form.value.fromCompanyId != null"
        >
          <mat-label>To Company</mat-label>
          <mat-select
            matNativeControl
            name="toCompanyId"
            formControlName="toCompanyId"
          >
            <mat-option *ngFor="let item of toCompanies" [value]="item.id">{{
              item.shortName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <br />
      <br />
      <!-- <div class="row"><strong>CLIENT DETAILS: </strong></div> -->
      <ng-container *ngIf="form.value.fromUserId != null">
        <div class="row">
          <mat-form-field appearance="standard" class="col-md-3">
            <mat-label>First Name</mat-label>
            <input
              matInput
              placeholder="Type first name"
              autocomplete="doNotAutoComplete"
              formControlName="firstName"
              #firstNameInput
            />
            <mat-error>First name is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="standard" class="col-md-3">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              placeholder="Type last name"
              autocomplete="doNotAutoComplete"
              formControlName="lastName"
              #lastNameInput
            />
            <mat-error>Last name is required</mat-error>
          </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field appearance="standard" class="col-md-4">
            <mat-label>Email Address:</mat-label>
            <input
              matInput
              autocomplete="doNotAutoComplete"
              type="email"
              formControlName="emailAddress"
              placeholder="Your email"
            />
            <mat-icon matSuffix>mail_outline</mat-icon>
            <!-- <mat-error *ngIf="(form.get('email')).errors?.required">Email is required</mat-error>
        <mat-error *ngIf="(form.get('email')).errors?.pattern">Please enter valid email</mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="standard" class="col-md-2">
            <mat-label>Phone Number:</mat-label>
            <input
              matInput
              inputmode="tel"
              autocomplete="doNotAutoComplete"
              formControlName="phoneNumber"
              placeholder="Your phone"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col-md-6">
            <mat-label style="color: red"
              ><strong>GOOGLE</strong> ADDRESS (Auto-Complete)</mat-label
            >
            <input
              matInput
              matGoogleMapsAutocomplete
              formControlName="address"
              autocomplete="doNotAutoComplete"
              country="au"
              (onAutocompleteSelected)="onAutocompleteSelected($event)"
              (onLocationSelected)="onLocationSelected($event)"
            />
          </mat-form-field>
        </div>

        <br />
        <br />
        <div>
          <!-- <div><strong> MANUAL INPUT: </strong></div> -->
          <div class="row">
            <mat-form-field appearance="fill" class="col-md-6">
              <mat-label>Manual Street Number:</mat-label>
              <input
                autocomplete="doNotAutoComplete"
                matInput
                formControlName="street_number"
                aria-readonly="true"
              />
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field appearance="fill" class="col-md-6">
              <mat-label>Manual Address Line:</mat-label>
              <input
                matInput
                autocomplete="doNotAutoComplete"
                formControlName="route"
              />
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field appearance="fill" class="col-md-2">
              <mat-label>Manual Suburb:</mat-label>
              <input
                matInput
                autocomplete="doNotAutoComplete"
                formControlName="locality"
              />
            </mat-form-field>

            <mat-form-field
              appearance="fill"
              hintLabel="State required"
              class="col-md-2"
            >
              <mat-label>Manual State</mat-label>
              <input
                matInput
                autocomplete="doNotAutoComplete"
                formControlName="administrative_area_level_1"
              />
              <!-- <mat-option *ngFor="let item of states" [value]="item">{{
              item
            }}</mat-option> -->
            </mat-form-field>

            <mat-form-field appearance="fill" class="col-md-2">
              <mat-label>Manual Post Code:</mat-label>
              <input
                matInput
                autocomplete="doNotAutoComplete"
                formControlName="postal_code"
              />
            </mat-form-field>
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <mat-form-field appearance="standard" class="col-md-6">
            <mat-label><strong>NOTES: </strong></mat-label>
            <textarea
              matInput
              rows="5"
              placeholder="Note"
              formControlName="note"
            ></textarea>
          </mat-form-field>
        </div>
        <br />
        <br />
        <div class="row">
          <!-- <input class="ng-hide" id="input-file-id" multiple type="file" />
        <label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label> -->
          <!-- <div class="dropzone">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
          <img src="assets/" />
          <h3>Drag and drop file here</h3>
          <h3>or</h3>
          <label for="fileDropRef">Browse for file</label>
        </div> -->
          <div class="col-md-6">
            <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
              <input
                type="file"
                #fileDropRef
                id="fileDropRef"
                multiple
                (change)="fileBrowseHandler($event.target)"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="63"
                height="64"
                viewBox="0 0 63 64"
              >
                <g fill="#3B454F" fill-rule="nonzero">
                  <path
                    d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z"
                  />
                  <path
                    d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z"
                  />
                  <path
                    d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z"
                  />
                </g>
              </svg>

              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label for="fileDropRef">Browse for file</label>
            </div>

            <div class="files-list">
              <div
                class="single-file"
                *ngFor="let file of files; let i = index"
              >
                <div class="file-icon" style="width: 50px">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 58 58"
                    style="enable-background: new 0 0 58 58"
                    xml:space="preserve"
                  >
                    <polygon
                      style="fill: #edeada"
                      points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 "
                    />
                    <g>
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                      />
                      <path
                        style="fill: #cec9ae"
                        d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                      />
                    </g>
                    <polygon
                      style="fill: #cec9ae"
                      points="37.5,0 37.5,14 51.5,14 "
                    />
                  </svg>
                </div>
                <div class="info">
                  <h4 class="name">
                    {{ file?.name }}
                  </h4>
                </div>

                <div class="delete" (click)="deleteFile(i)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                  >
                    <path
                      fill="#B1B1B1"
                      fill-rule="nonzero"
                      d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <br />

      <div class="row endrow">
        <div mat-dialog-actions align="start" class="col-md-3">
          <button mat-raised-button color="basic" (click)="goBack()">
            Back
          </button>
        </div>
        <div mat-dialog-actions align="end" class="col-md-3">
          <mat-spinner
            *ngIf="isProcessing"
            style="float: right"
            [diameter]="30"
          ></mat-spinner>
          <button
            *ngIf="!isProcessing"
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!form.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
  <div
    *ngIf="success"
    class="alert alert-success text-center mt-3"
    role="alert"
  >
    Lead created successfully!
  </div>
  <div>
    <br />
    <br />
  </div>
  <!-- <div>{{ form.value | json }}</div> -->
</div>
