import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LeadsService } from '../services/leads.service';
import { Filter } from './filter/filter';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['/../../styles/variables.scss', './home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  filter: Filter = new Filter();
  events: string[] = [];

  @ViewChild(MatSort) sort = new MatSort();
  displayedColumns: string[] = [
    'rating',
    'agent',
    'toCompany',
    'firstName',
    'address',
    'crmStatus',
    'commission',
    'createdAt',
  ];
  summaryColumns: string[] = [
    'agent',
    'leads',
    'new',
    'qualified',
    'won',
    'commission',
  ];
  summaryTotalColumns: string[] = [
    'agent',
    'leads',
    'new',
    'qualified',
    'won',
    'commission',
  ];
  expandedElement: any = [];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];

  currentUserId: any;
  currentCompanyId: any = 0;
  showCommission = false;
  userCanChange: boolean = false;
  users = [];

  leadsCount: number = 0;
  commissionTotal: number = 0;

  reviewComment: string = '';

  constructor(private leadsService: LeadsService, private router: Router) {}

  ngOnInit() {
    this.leadsService.getCompanies().subscribe(
      (res) => {
        this.leadsService.getCurrentUser().subscribe((userResult: any) => {
          if (userResult != null) {
            this.currentUserId = userResult.id;
            this.currentCompanyId = userResult.companyId;
            this.userCanChange = userResult.canChange;
          }
          this.getLeads();
        });
      },
      (err) => {
        if (err.status == 401) this.router.navigateByUrl('unauthorized');
      }
    );
  }

  ConvertUTC(utcDateTime: Date, TZOffset?: boolean, time?: boolean) {
    let toDate = new Date(utcDateTime);
    let offset = TZOffset ? toDate.getTimezoneOffset() / 60 : 0;
    toDate.setHours(toDate.getHours() - offset);
    let date = toDate.toLocaleString('en-AU');
    date = time ? date : date.split(',')[0];
    return date;
  }

  toCRM(toCompanyId: number, CRMId: string, crmStatus: string) {
    let url = '';
    let urlExt = '';
    let statusLost = crmStatus.startsWith('LOST');
    let statusNew = crmStatus.startsWith('NEW');
    let statusQualified =
      crmStatus.startsWith('QUALIFIED') || crmStatus.startsWith('WON');

    if (toCompanyId == 1) {
      //PipeDrive
      url = environment.pipeDrive.url;
      if (statusNew || statusLost) urlExt = environment.pipeDrive.leads;
      else if (statusQualified) urlExt = environment.pipeDrive.deals;
    } else if (toCompanyId == 2) {
      //Zoho
      url = environment.zoho.url;
      if (statusNew || statusLost) urlExt = environment.zoho.leads;
      else if (statusQualified) urlExt = environment.zoho.deals;
    } else if (toCompanyId == 3) {
      //SWCPipedrive
      url = environment.SWC.url;
      if (statusNew || statusLost) {
        urlExt = environment.SWC.leads;
      } else if (statusQualified) urlExt = environment.SWC.deals;
    }
    window.open(url + urlExt + CRMId, '_blank');
  }

  getLeads() {
    this.leadsService.getLeads().subscribe((leads: any) => {
      this.updateSummary(leads);
      this.filter.leadSource = new MatTableDataSource(leads);
      //Can disable Sorting here due to conflict with Row Expansion
      // this.leadSource.sort = this.sort;
      this.applyFilterPredicate();
      this.filter.applyFilters(
        new Date(this.filter.dateFortnight),
        new Date(this.filter.dateToday)
      );
    });
  }

  updateSummary(obj: [] = this.filter.leadSource.filteredData) {
    this.leadsCount = Object.keys(obj).length;
    this.updateLeadCommission(obj, 'commission');
  }

  applyFilterPredicate() {
    this.filter.leadSource.filterPredicate = function (
      data: any,
      filter: [Date, Date, number?]
    ): boolean {
      if (filter[0] == null || (filter[0] == null && filter[2] == null)) {
        if (filter[2] != null) return data.fromUserId == filter[2];
        return true;
      }
      if (filter[2] != null) {
        return (
          data.fromUserId == filter[2] &&
          new Date(data.createdAt).getTime() > filter[0].getTime() &&
          new Date(data.createdAt).getTime() < filter[1].getTime()
        );
      }
      return (
        new Date(data.createdAt).getTime() > filter[0].getTime() &&
        new Date(data.createdAt).getTime() < filter[1].getTime()
      );
    };
  }

  onValueChange(event: Event, lead: any): void {
    const value = (event.target as any).value;
    lead.reviewNotes = value;
  }

  submitRating(lead: any) {
    this.leadsService.updateRating(lead.id, lead.rating, lead.reviewNotes);
  }

  patchCRMStatus() {
    this.leadsService.publishUnpublished();
    this.leadsService.updateCRMStatus();
  }

  //Update this to be usable for other Values
  updateLeadCommission(obj: [], value: string) {
    this.commissionTotal = obj
      .map((obj: any) => obj[value])
      .reduce((partialSum: number, a: number) => partialSum + a, 0);
  }
}
