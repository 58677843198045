import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { protectedResources } from '../auth-config';

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  url = protectedResources.api.endpoint + '/api/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {}

  getLeads() {
    return this.http.get(this.url + 'leads');
  }

  getCompanies() {
    return this.http.get(this.url + 'companies');
  }

  getActiveCompanyUsers(companyId: number) {
    return this.http.get(this.url + 'users/company/' + companyId);
  }

  getBCSQuoteId(crmId: any) {
    return this.http.get(this.url + `leads/bcs/quoteId/${crmId}`);
  }

  createLead(lead: any, files: any[]) {
    const uploadData = new FormData();
    files.forEach((f) => uploadData.append('files', f));
    uploadData.append('newLead', JSON.stringify(lead));
    return this.http
      .post(this.url + 'leads', uploadData, {
        reportProgress: true,
        observe: 'events',
      })
      .toPromise();
  }

  updateRating(leadId: number, rating: number, reviewNotes: string) {
    const uploadData = new FormData();
    uploadData.append('Id', JSON.stringify(leadId));
    uploadData.append('Value', JSON.stringify(rating));
    uploadData.append('Notes', reviewNotes);
    console.log(reviewNotes);
    return this.http
      .patch(this.url + 'leads/rating', uploadData, {
        reportProgress: true,
        observe: 'events',
      })
      .toPromise();
  }

  publishUnpublished() {
    return this.http
      .put(this.url + 'leads/publish', '', {
        reportProgress: true,
        observe: 'events',
      })
      .toPromise();
  }

  updateCRMStatus() {
    return this.http
      .patch(this.url + 'leads/CRM', '', {
        reportProgress: true,
        observe: 'events',
      })
      .toPromise();
  }

  getCurrentUser() {
    return this.http.get(this.url + 'users/current');
  }
}
