<style>
  .starColor {
    color: var(--rating-stars-color);
  }
  .starColorAlt {
    color: var(--rating-stars-color-alt);
  }
</style>

<button
  mat-icon-button
  style="width: fit-content"
  [disabled]="locked"
  (click)="rating = 0"
>
  <mat-icon *ngIf="rating > 0" style="color: rgba(0, 0, 0, 0.158)"
    >clear</mat-icon
  >
  <mat-icon *ngIf="rating == 0" style="color: rgba(0, 0, 0, 0.158)"></mat-icon>
</button>
<button
  mat-icon-button
  style="width: fit-content"
  *ngFor="let ratingId of ratingArr; index as i"
  [id]="'star_' + i"
  (click)="rating = i + 1"
  [disabled]="locked"
>
  <mat-icon [ngClass]="{ starColor: locked, starColorAlt: !locked }">
    {{ showIcon(i) }}
  </mat-icon>
</button>
